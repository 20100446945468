import React from 'react'
import styled from 'styled-components'

// Elements
import CustomLink from 'components/shared/CustomLink'

const Row = styled.div`
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @media (max-width: 991px) {
    gap: 1rem;
  }
`

const StyledCustomLink = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.secondary};
  border: 1px solid ${(props) => props.theme.color.face.secondary};
  border-radius: 5px;
  padding: 12px 25px;
  font-weight: 700;
  text-transform: uppercase;
  width: 190px;

  &:hover {
    color: ${(props) => props.theme.color.text.dark};
    border-color: ${(props) => props.theme.color.face.dark};
  }
`

const StyledImage = styled.img`
  max-height: 40px;
  max-width: 40px;
  min-width: 40px;
`

interface LinksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Links
}

const Links: React.FC<LinksProps> = ({ fields }) => (
  <section className="container">
    <div className="py-lg-5">
      <Row className="row py-lg-0 py-4 justify-content-center align-items-center">
        {fields?.linkgroup?.map((edge, index: number) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="d-flex justify-content-center align-items-center"
          >
            <StyledCustomLink
              to={edge?.link?.url || ''}
              target={edge?.link?.target || '_self'}
              className="d-flex align-items-center justify-content-center font-size-base"
            >
              {edge?.image && edge?.image?.localFile?.publicURL ? (
                <div className="d-inline-flex align-items-center w-100 justify-content-between">
                  <StyledImage
                    src={edge?.image?.localFile?.publicURL}
                    className="mr-3"
                    alt="Lofthus"
                  />
                  {edge?.link?.title}
                </div>
              ) : (
                edge?.link?.title
              )}
            </StyledCustomLink>
          </div>
        ))}
      </Row>
    </div>
  </section>
)

export default Links
